var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-snackbar', {
    attrs: {
      "top": "",
      "color": _vm.notification.type,
      "multi-line": false,
      "timeout": 6000,
      "vertical": false,
      "closable": ""
    },
    scopedSlots: _vm._u([{
      key: "action",
      fn: function fn(_ref) {
        var attrs = _ref.attrs;
        return [_c('v-btn', _vm._b({
          attrs: {
            "color": "white",
            "text": ""
          },
          on: {
            "click": function click($event) {
              _vm.notification.show = false;
            }
          }
        }, 'v-btn', attrs, false), [_vm._v(_vm._s(_vm.$t('snack.fermer')))])];
      }
    }]),
    model: {
      value: _vm.notification.show,
      callback: function callback($$v) {
        _vm.$set(_vm.notification, "show", $$v);
      },
      expression: "notification.show"
    }
  }, [!_vm.notification.cause ? _c('div', [_vm._v(_vm._s(_vm.notification.msg))]) : _vm._e(), _vm.notification.cause ? _c('details', [_c('summary', [_vm._v(_vm._s(_vm.notification.msg))]), _c('div', [_vm._v(_vm._s(_vm.notification.cause))])]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }