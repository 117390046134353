














import { Vue, Component } from "vue-property-decorator";
import messageStore, {
  MessageStateModel,
  NotificationModel,
} from "@/stores/messageStore";

@Component
export default class Snack extends Vue {
  messageState: MessageStateModel = messageStore.state;

  get notification(): NotificationModel {
    return this.messageState.notification;
  }
}
